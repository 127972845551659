<template>
  <div>
    <v-row class="mx-10 my-2" align="center">
      <div>
        <h1>Preview Cards</h1>
        <div>
          {{
            $route.path == '/create-cards-template'
              ? 'Create Users Card Template'
              : 'Update Users Card Template'
          }}
        </div>
        <div v-if="$route.path == '/create-cards-template' && withoutCardUsers">
          <h4>
            {{ withoutCardUsers.length }} Users - {{ cards.length }} Cards
          </h4>
        </div>
        <div v-else-if="cards && cards.length">{{ cards.length }} Cards</div>
        <div v-if="company">Company - {{ company.name }}</div>
      </div>
      <v-spacer></v-spacer>
      <v-progress-circular
        v-if="loading"
        class="my-10"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <v-btn
        v-else-if="cards && cards.length > 0"
        color="primary"
        @click="saveAllCards"
        >Save All Cards</v-btn
      >
    </v-row>
    <v-row class="mx-10 my-2" align="center" justify="center">
      <v-progress-circular
        v-if="pageLoading"
        class="my-10"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>
    <v-list id="all-cards" v-if="cards && cards.length" two-line>
      <template v-for="(card, index) in cards">
        <v-list-item >
          <v-list-item-content :key="index">
            <v-row class="ma-0" align="center">
              <v-checkbox
                v-if="card.hasOwnProperty('isSelected')"
                v-model="card.isSelected"
                label=""
              ></v-checkbox>
              <card-draggable
                v-if="card"
                class="my-10"
                :cardData="card"
                :user="users.find((e) => e.uid == card.userId)"
                :disableFileInput="true"
                :screenshotId="card.id"
                :backgroundCardImage="backgroundCardImage"
                :logoCardImage="logoCardImage"
              />
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
import CardDraggable from '../components/CardDraggable.vue';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import firebase from 'firebase/app';
const htmlToImage = require('html-to-image');

export default {
  name: 'AllCardsTemplate',
  components: { CardDraggable },
  data() {
    return {
      company: null,
      cards: [],
      backgroundCardImage: null,
      logoCardImage: null,
      loading: false,
      withoutCardUsers: [],
      pageLoading: false,
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user,
      users: (state) => state.users,
      hradmin: (state) => state.hradmin,
    }),
    ...mapGetters(['getRole']),
  },
  mounted() {
    if (this.getRole?.id != this.hradmin) {
      this.$router.push('/');
      return;
    }
  },
  async created() {
    this.pageLoading = true;
    this.company = await this.fetchCompany(this.currentUser.companyId);
    const cards = await this.fetchCards(this.currentUser.companyId);

    if (this.$route.path == '/create-cards-template') {
      await this.fetchUsers();
      const withoutCardUsers = [];
      this.users.forEach((user) => {
        const foundUserCard = cards.find((e) => user.uid == e.userId);
        if (foundUserCard) {
          // console.log('already have card', user.uid);
        } else {
          // console.log('dont have card', user.uid, user.name);
          withoutCardUsers.push(user);
        }
      });
      this.withoutCardUsers = withoutCardUsers;
      this.cards = withoutCardUsers.map((user) => {
        const autoGeneratedDoc = firebase.firestore().collection('cards').doc();
        const card = {
          emails: [],
          name: user.name,
          designation: user.designation || '',
          userId: user.uid,

          qualification: '',
          category: '',
          tags: [],
          moreInfo: [],
          deleted: false,

          isPrivate: true,
          enableChat: true,

          createDate: new Date().getTime(),
          updateDate: new Date().getTime(),

          screenshotPath: '',
          createdBy: this.currentUser.uid,

          address: this.company.address,
          businessHours: this.company.businessHours,
          company: this.company.cardCompanyName,
          design: this.company.design,
          elementDesigns: this.company.elementDesigns,
          phoneNumbers: [],
          socialLinks: this.company.socialLinks,
          website: this.company.website,
          isoCode:
            this.company?.phoneNumbers?.length > 0
              ? this.company.phoneNumbers[0].isoCode
              : 'IN',
          companyId: this.company.id,
          id: autoGeneratedDoc.id,
        };
        card.phoneNumbers =
          user.phone && user.countryCode
            ? [
                {
                  countryCode: user.countryCode,
                  phone: user.phone,
                  type: 'Mobile',
                  ext: '',
                  isoCode: user.isoCode || card.isoCode,
                  country: '',
                },
                ...this.company.phoneNumbers,
              ]
            : this.company.phoneNumbers;
        card.phoneNumbers = card.phoneNumbers.filter(
          (e) => e.phone && e.countryCode
        );
        card.emails = user.email
          ? [user.email, ...this.company.emails]
          : this.company.emails;
        card.emails = card.emails.filter((e) => e);
        card.tags = this.company.tags;
        card.moreInfo = this.company.moreInfo;
        return card;
      });
    } else {
      this.fetchUsers();
      this.cards = cards.map((card) => {
        card.address = this.company.address;
        card.businessHours = this.company.businessHours;
        card.company = this.company.cardCompanyName;
        card.design = this.company.design;
        let elementDesigns = card.elementDesigns;
        if (elementDesigns && !this.company.applyElementPosition) {
          const mixDesign = {};
          Object.keys(this.company.elementDesigns).forEach((key) => {
            mixDesign[key] = {
              ...this.company.elementDesigns[key],
              left: elementDesigns[key].left,
              right: elementDesigns[key].right,
              top: elementDesigns[key].top,
              bottom: elementDesigns[key].bottom,
            };
          });
          card.elementDesigns = mixDesign;
        } else {
          card.elementDesigns = this.company.elementDesigns;
        }
        const user = this.users.find((e) => e.uid == card.userId);
        card.isoCode =
          this.company?.phoneNumbers?.length > 0
            ? this.company.phoneNumbers[0].isoCode
            : 'IN';
        card.phoneNumbers =
          user.phone && user.countryCode
            ? [
                {
                  countryCode: user.countryCode,
                  phone: user.phone,
                  type: 'Mobile',
                  ext: '',
                  isoCode: user.isoCode || card.isoCode,
                  country: '',
                },
                ...this.company.phoneNumbers,
              ]
            : this.company.phoneNumbers;
        card.phoneNumbers = card.phoneNumbers.filter(
          (e) => e.phone && e.countryCode
        );
        card.emails = user.email
          ? [user.email, ...this.company.emails]
          : this.company.emails;
        card.emails = card.emails.filter((e) => e);
        card.tags = this.company.tags;
        card.moreInfo = this.company.moreInfo;
        card.socialLinks = this.company.socialLinks;
        card.website = this.company.website;
        card.companyId = this.company.id;
        card.isSelected = true;

        return card;
      });
    }
    setTimeout(() => {
      this.applyCustomFont(
        null,
        {
          name: this.company.design.fontName,
          url: this.company.design.fontUrl,
        },
        ''
      );
    }, 100);
    let bgImage = await (await fetch(this.company.design.bgUrl))?.blob();
    if (bgImage) {
      this.backgroundCardImage = {
        file: bgImage,
        previewUrl: URL.createObjectURL(bgImage),
        uploadValue: 0,
      };
    }
    let logoImage = await (await fetch(this.company.design.logoUrl))?.blob();
    if (logoImage) {
      this.logoCardImage = {
        file: logoImage,
        previewUrl: URL.createObjectURL(logoImage),
        uploadValue: 0,
      };
    }
    this.pageLoading = false;
  },
  methods: {
    ...mapActions(['fetchUsers', 'fetchCompany', 'fetchCards']),
    ...mapMutations(['setSelectedNode']),
    applyCustomFont(data, urlData, cardId) {
      // upload
      // response of font path
      // create style tag
      let fileName = '';
      let url = '';
      if (data) {
        fileName = data.file.name;
        url = data.previewUrl;
      } else if (urlData) {
        fileName = urlData.name;
        url = urlData.url;
      }

      console.log(fileName, url);

      if (fileName && url) {
        const customFontEl = document.getElementById('custom-font');
        if (customFontEl) {
          // truthy: non-empty string, true, {}, [], >0
          // false: 0, null, undefine, ''
          customFontEl.innerHTML = `
              @font-face {
              font-family: '${fileName}';
              src: url(${url});
            }
            `;
        } else {
          const el = document.createElement('style');
          el.id = 'custom-font';
          el.innerHTML = `
              @font-face {
                font-family: '${fileName}';
                src: url(${url});
              }
              `;
          document.head.appendChild(el);
        }
        //card-screenshot-${cardId}
        if (document.getElementById(`all-cards`)) {
          document.getElementById(
            `all-cards`
          ).style.fontFamily = `'${fileName}'`;
        }
      }
    },
    async saveAllCards() {
      this.setSelectedNode(null);
      this.loading = true;
      const batch = firebase.firestore().batch();
      const promises = this.cards.map(async (card) => {
        console.log(card.id, card?.isSelected);

        if (card.isSelected || this.$route.path == '/create-cards-template') {
          const imageBlob = await htmlToImage.toBlob(
            document.getElementById(`card-screenshot-${card.id}`)
          );
          // console.log(imageBlob);
          // let a = document.createElement('a');
          // a.href = window.URL.createObjectURL(imageBlob);
          // a.target = '_blank';
          // a.click();

          const folder = `screenshot`;
          const fileName = `${card.userId}-${card.id}.png`;

          const storageRef = firebase
            .storage()
            .ref(`${folder}/${fileName}`)
            .put(imageBlob);
          await storageRef.then(
            async (snapshot) => {
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  // console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  // console.log('Upload is running');
                  break;
                case firebase.storage.TaskState.CANCELED: // or 'running'
                  // console.log('Upload is canceled');
                  break;
                case firebase.storage.TaskState.ERROR: // or 'running'
                  // console.log('Upload is error');
                  break;
                case firebase.storage.TaskState.SUCCESS: // or 'running'
                  // console.log('Upload is success');
                  const url = await snapshot.ref.getDownloadURL();
                  // console.log(card.id, url);
                  if (url) {
                    card.screenshotPath = url;
                  }
                  break;
              }
            },
            (error) => {
              console.log(error.message);
            }
          );

          const ref = firebase.firestore().collection('cards').doc(card.id);
          batch.set(ref, card, { merge: true });
        }
        return card;
      });
      await Promise.all(promises);
      await batch.commit();
      this.loading = false;
      if (this.$route.path == '/create-cards-template') {
        alert(`new cards created successfully.`);
      } else {
        alert(`all cards updated successfully.`);
      }
      this.$router.push('/');
    },
  },
};
</script>

<style lang="scss"></style>
